<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="'Let’s create a lesson plan!'"
      :subtitle="''"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3 pt-2"
      :shape="'circle'"
      @on-complete="formSubmitted"
    >
      <tab-content title="" :before-change="validationForm">
        <b-row class="justify-content-center py-2">
          <p class="text-dark">We would like you to answer a few questions to get started.</p>
        </b-row>
        <b-row>
          <b-col md="2"></b-col>
          <b-col md="8">
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2"> </b-col>
                <b-col md="6">
                  <b-form-group
                    label="How many students in your class?"
                    label-for="studentsNumber"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="studentsNumber"
                      rules="required"
                    >
                      <b-form-input
                        id="studentsNumber"
                        v-model="form.studentsNumber"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                        type="number"
                        placeholder="Number of students"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="What is the length of your class?"
                    label-for="classLength"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="classLength"
                      rules="required"
                    >
                      <v-select
                        v-model="form.classLength"
                        :options="['20', 60, 30]"
                        class="w-100 mb-0"
                        placeholder="Time in minutes, ex (30 min)"
                        id="classLength"
                      >
                        <!-- <template v-slot:option="option">
                          {{ $i18n.locale == "ar" ? option.name_local : option.name }}
                        </template> -->
                        <!-- <template #selected-option="{ name, name_local }">
                          <div style="display: flex; align-items: baseline">
                            <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                            <strong v-else>{{ name }} </strong>
                          </div>
                        </template> -->
                        <template #no-options>
                          {{ $t("noMatching") }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="How many times a week?"
                    label-for="weekTimes"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="weekTimes"
                      vid="weekTimes"
                      rules="required"
                    >
                      <!-- <b-form-input
                        id="weekTimes"
                        v-model="form.weekTimes"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Once a week"
                        autocomplete="off"
                      /> -->
                      <v-select
                        v-model="form.weekTimes"
                        :options="['Once a week', 'Twice a week']"
                        class="w-100 mb-0"
                        placeholder="Once a week"
                        id="weekTimes"
                      >
                        <!-- <template v-slot:option="option">
                          {{ $i18n.locale == "ar" ? option.name_local : option.name }}
                        </template> -->
                        <!-- <template #selected-option="{ name, name_local }">
                          <div style="display: flex; align-items: baseline">
                            <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                            <strong v-else>{{ name }} </strong>
                          </div>
                        </template> -->
                        <template #no-options>
                          {{ $t("noMatching") }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="What level would you like to start with?"
                    label-for="startLevel"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Start Level"
                      rules="required"
                    >
                      <v-select
                        v-model="form.startLevel"
                        :options="['Level 1', 'Level 2', 'Level 3']"
                        class="w-100 mb-0"
                        placeholder="Level 1"
                        id="startLevel"
                      >
                        <template #no-options>
                          {{ $t("noMatching") }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <div class="d-flex justify-content-between align-items-center pl-1 pt-2">
                  <b-form-checkbox
                    id="patymentTerms"
                    :checked="false"
                    v-model="form.participate"
                    switch
                  />
                  <label for="patymentTerms">I would you like to participate in a global school competition</label>
                </div>
              </b-row>
            </validation-observer>
          </b-col>
          <b-col md="2"></b-col>
        </b-row>
      </tab-content>

      <tab-content title="">
        <b-row class="justify-content-center py-2">
          <p class="text-dark mb-2">Please select the modules you would like to add to your lesson plan.</p>
        </b-row>
        <b-row>
          <b-col md="12" lg="1" style="width: 12.499999995%; flex: 0 0 12.499%;max-width: 12.499%;"></b-col>
          <b-col md="12" lg="3" class="d-flex justify-content-center">
            <b-card
              img-src="https://picsum.photos/600/300/?image=25"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 20rem; border-radius: 15px;"
              class="mb-2"
            >
              <b-card-title>
                <div class="d-flex justify-content-between">
                  Title
                  <b-link  class="text-primary" style="font-size: 14px;" :to="{ name: 'preview_module' }">Preview</b-link>
                </div>
              </b-card-title>
              <small>
                Description text will be added here...
              </small>
              <div class="w-100 d-flex justify-content-between mt-2">
                <small style="padding-top: 0.8rem !important;">
                  8 Lessons
                </small>
                <b-button href="#" variant="primary" class="rounded-circle" style="padding: 2px">
                  <feather-icon
                    icon="PlusIcon"
                    size="23"
                  />
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col md="12" lg="3" class="d-flex justify-content-center">
            <b-card
              img-src="https://picsum.photos/600/300/?image=25"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 20rem; border-radius: 15px;"
              class="mb-2"
            >
              <b-card-title>
                <div class="d-flex justify-content-between">
                  Title
                  <a href="#" class="text-primary" style="font-size: 14px;">Preview</a>
                </div>
              </b-card-title>
              <small>
                Description text will be added here...
              </small>
              <div class="w-100 d-flex justify-content-between mt-2">
                <small style="padding-top: 0.8rem !important;">
                  8 Lessons
                </small>
                <b-button href="#" variant="primary" class="rounded-circle" style="padding: 2px">
                  <feather-icon
                    icon="PlusIcon"
                    size="23"
                  />
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col md="12" lg="3" class="d-flex justify-content-center">
            <b-card
              img-src="https://picsum.photos/600/300/?image=25"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 20rem; border-radius: 15px;"
              class="mb-2"
            >
              <b-card-title>
                <div class="d-flex justify-content-between">
                  Title
                  <a href="#" class="text-primary" style="font-size: 14px;">Preview</a>
                </div>
              </b-card-title>
              <small>
                Description text will be added here...
              </small>
              <div class="w-100 d-flex justify-content-between mt-2">
                <small style="padding-top: 0.8rem !important;">
                  8 Lessons
                </small>
                <b-button href="#" variant="primary" class="rounded-circle" style="padding: 2px">
                  <feather-icon
                    icon="PlusIcon"
                    size="23"
                  />
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col md="12" lg="1" style="width: 12.499999995%; flex: 0 0 12.499%;max-width: 12.499%;"></b-col>
        </b-row>
        <b-row>
          <b-col md="12" lg="1" style="width: 12.499999995%; flex: 0 0 12.499%;max-width: 12.499%;"></b-col>
          <b-col md="12" lg="3" class="d-flex justify-content-center">
            <b-card
              img-src="https://picsum.photos/600/300/?image=25"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 20rem; border-radius: 15px;"
              class="mb-2"
            >
              <b-card-title>
                <div class="d-flex justify-content-between">
                  Title
                  <a href="#" class="text-primary" style="font-size: 14px;">Preview</a>
                </div>
              </b-card-title>
              <small>
                Description text will be added here...
              </small>
              <div class="w-100 d-flex justify-content-between mt-2">
                <small style="padding-top: 0.8rem !important;">
                  8 Lessons
                </small>
                <b-button href="#" variant="primary" class="rounded-circle" style="padding: 2px">
                  <feather-icon
                    icon="PlusIcon"
                    size="23"
                  />
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col md="12" lg="3" class="d-flex justify-content-center">
            <b-card
              img-src="https://picsum.photos/600/300/?image=25"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 20rem; border-radius: 15px;"
              class="mb-2"
            >
              <b-card-title>
                <div class="d-flex justify-content-between">
                  Title
                  <a href="#" class="text-primary" style="font-size: 14px;">Preview</a>
                </div>
              </b-card-title>
              <small>
                Description text will be added here...
              </small>
              <div class="w-100 d-flex justify-content-between mt-2">
                <small style="padding-top: 0.8rem !important;">
                  8 Lessons
                </small>
                <b-button href="#" variant="primary" class="rounded-circle" style="padding: 2px">
                  <feather-icon
                    icon="PlusIcon"
                    size="23"
                  />
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col md="12" lg="3" class="d-flex justify-content-center">
            <b-card
              img-src="https://picsum.photos/600/300/?image=25"
              img-alt="Image"
              img-top
              tag="article"
              style="max-width: 20rem; border-radius: 15px;"
              class="mb-2"
            >
              <b-card-title>
                <div class="d-flex justify-content-between">
                  Title
                  <a href="#" class="text-primary" style="font-size: 14px;">Preview</a>
                </div>
              </b-card-title>
              <small>
                Description text will be added here...
              </small>
              <div class="w-100 d-flex justify-content-between mt-2">
                <small style="padding-top: 0.8rem !important;">
                  8 Lessons
                </small>
                <b-button href="#" variant="primary" class="rounded-circle" style="padding: 2px">
                  <feather-icon
                    icon="PlusIcon"
                    size="23"
                  />
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col md="12" lg="1" style="width: 12.499999995%; flex: 0 0 12.499%;max-width: 12.499%;"></b-col>
        </b-row>
      </tab-content>

      <!-- address  -->
      <tab-content title="">
        <b-row class="justify-content-center py-2">
          <p class="text-dark">We would like you to answer a few questions to get started.</p>
        </b-row>
        <b-card
          tag="article"
          class="mb-3 w-100"
        >
          <!-- <b-card-sub-title>
              <small>CATEGORY</small>
          </b-card-sub-title> -->
          <b-card-title>
              <h3 class="mb-2">Lesson plan information</h3>
          </b-card-title>
          <b-card-text>
              <b-row>
                <b-col md="6">
                  <b-col cols="12">
                    <p class="mb-0">How many students in your class?</p>
                    <strong>40</strong>
                  </b-col>
                  <b-col cols="12">
                    <p class="mb-0">How many times a week?</p>
                    <strong>Once a week</strong>
                  </b-col>
                  <b-col cols="12">
                    <p class="mb-0">I would like to participate in a global school competion</p>
                    <strong>No</strong>
                  </b-col>
                </b-col>
                <b-col md="6">
                  <b-col cols="12">
                    <p class="mb-0">What is the length of your class?</p>
                    <strong>1 hour</strong>
                  </b-col>
                  <b-col cols="12">
                    <p class="mb-0">What level would you like to start with?</p>
                    <strong>Level 1</strong>
                  </b-col>
                </b-col>
              </b-row>
          </b-card-text>
        </b-card>
        <b-card
          tag="article"
          class="mb-3 w-100"
        >
          <!-- <b-card-sub-title>
              <small>CATEGORY</small>
          </b-card-sub-title> -->
          <b-card-title>
              <h3 class="mb-2">Modules added</h3>
          </b-card-title>
          <b-card-text>
              <b-row>
                <b-col md="12" lg="3" class="d-flex justify-content-center">
                  <b-card
                    img-src="https://picsum.photos/600/300/?image=25"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 20rem; border-radius: 15px;"
                    class="mb-2"
                  >
                    <b-card-title>
                      <div class="d-flex justify-content-between">
                        Title
                        <b-link  class="text-primary" style="font-size: 14px;" :to="{ name: 'preview_module' }">Preview</b-link>
                      </div>
                    </b-card-title>
                    <small>
                      Description text will be added here...
                    </small>
                    <div class="w-100 d-flex justify-content-between mt-2">
                      <small style="padding-top: 0.6rem !important;">
                        8 Lessons
                      </small>
                      <b-button href="#" variant="primary" class="rounded-circle" style="padding: 2px">
                        <feather-icon
                          icon="PlusIcon"
                          size="23"
                        />
                      </b-button>
                    </div>
                  </b-card>
                </b-col>
                <b-col md="12" lg="3" class="d-flex justify-content-center">
                  <b-card
                    img-src="https://picsum.photos/600/300/?image=25"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 20rem; border-radius: 15px;"
                    class="mb-2"
                  >
                    <b-card-title>
                      <div class="d-flex justify-content-between">
                        Title
                        <a href="#" class="text-primary" style="font-size: 14px;">Preview</a>
                      </div>
                    </b-card-title>
                    <small>
                      Description text will be added here...
                    </small>
                    <div class="w-100 d-flex justify-content-between mt-2">
                      <small style="padding-top: 0.6rem !important;">
                        8 Lessons
                      </small>
                      <b-button href="#" variant="primary" class="rounded-circle" style="padding: 2px">
                        <feather-icon
                          icon="PlusIcon"
                          size="23"
                        />
                      </b-button>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
          </b-card-text>
        </b-card>
      </tab-content>
      <template slot="footer" slot-scope="props">
        <div class="pt-5">
          <div class="wizard-footer-left">
            
          </div>
          <div class="wizard-footer-right">
            <button
              @click="props.prevTab()"
              :style="props.fillButtonStyle"
              class="btn btn-light rounded-pill nav-btns mr-2"
            >
              Back
            </button>
            <button
              v-if="!props.isLastStep"
              @click="props.nextTab()"
              class="wizard-footer-right btn btn-primary rounded-pill nav-btns"
              :style="props.fillButtonStyle"
            >
              Continue
            </button>

            <button
              v-else
              @click="alert('Done')"
              :style="props.fillButtonStyle"
              class="wizard-footer-right btn btn-primary rounded-pill nav-btns"
            >
              {{ props.isLastStep ? "Publish" : "Next" }}
            </button>
          </div>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BLink,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { codeIcon } from "@/views/forms/form-wizard/code.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BCard,
    BCardText,
    BButton,
    BCardTitle,
    BLink,
    vSelect,
    ToastificationContent,
  },
  props: {
    title: {
      type: String,
      default: "Awesome Wizard",
    },
    subtitle: {
      type: String,
      default: "Split a complicated flow in multiple steps",
    },
    nextButtonText: {
      type: String,
      default: "Next",
    },
    backButtonText: {
      type: String,
      default: "Back",
    },
    finishButtonText: {
      type: String,
      default: "Finish",
    },
    stepSize: {
      type: String,
      default: "md",
      validator: (value) => {
        let acceptedValues = ["xs", "sm", "md", "lg"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    /***
     *  Sets validation (on/off) for back button. By default back button ignores validation
     */
    validateOnBack: Boolean,
    /***
     * Applies to text, border and circle
     */
    color: {
      type: String,
      default: "#e74c3c", //circle, border and text color
    },
    /***
     *  Is set to current step and text when beforeChange function fails
     */
    errorColor: {
      type: String,
      default: "#8b0000",
    },
    /**
     * Can take one of the following values: 'circle|square|tab`
     */
    shape: {
      type: String,
      default: "circle",
    },
    /**
     * name of the transition when transition between steps
     */
    transition: {
      type: String,
      default: "", //name of the transition when transition between steps
    },
    /***
     * Index of the initial tab to display
     */
    startIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        studentsNumber: null,
        classLength: null,
        weekTimes: null,
        startLevel: null,
        participate: false
      },
      selectedContry: "",
      selectedLanguage: "",
      name: "",
      emailValue: "",
      PasswordValue: "",
      passwordCon: "",
      first_name: "",
      last_name: "",
      address: "",
      landMark: "",
      pincode: "",
      twitterUrl: "",
      facebookUrl: "",
      googleUrl: "",
      linkedinUrl: "",
      city: "",
      required,
      email,
      codeIcon,
      countryName: [
        { value: "select_value", text: "Select Value" },
        { value: "Russia", text: "Russia" },
        { value: "Canada", text: "Canada" },
        { value: "China", text: "China" },
        { value: "United States", text: "United States" },
        { value: "Brazil", text: "Brazil" },
        { value: "Australia", text: "Australia" },
        { value: "India", text: "India" },
      ],
      languageName: [
        { value: "nothing_selected", text: "Nothing Selected" },
        { value: "English", text: "English" },
        { value: "Chinese", text: "Mandarin Chinese" },
        { value: "Hindi", text: "Hindi" },
        { value: "Spanish", text: "Spanish" },
        { value: "Arabic", text: "Arabic" },
        { value: "Malay", text: "Malay" },
        { value: "Russian", text: "Russian" },
      ],
    };
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
[dir]
  .vue-form-wizard
  .wizard-navigation
  .wizard-nav
  li
  .wizard-icon-circle
  .wizard-icon-container {
  border-radius: 50px !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle {
  border: none;
  background-color: #ededed;
  border-radius: 50px !important;
}

[dir] .vue-form-wizard .wizard-nav-pills {
  margin-top: 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

[dir="ltr"] .vue-form-wizard .wizard-navigation .wizard-nav li {
  margin-right: 1rem;
  margin-left: 1rem;
}
[dir] .vue-form-wizard {
    background-color: unset !important; 
    box-shadow: unset !important;
    border-radius: 0.5rem;
    padding-bottom: 0;
}
[dir] .vs__dropdown-toggle{
  background-color: #fff !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav{
  border-bottom: unset;
}
.vue-form-wizard .wizard-title {
    color: #252422;
    font-weight: 600;
    padding-bottom: 20px;
    font-size: 1.7rem;
}
label{
  color: #252422;
}
[dir] .custom-switch .custom-control-label::before {
    border: none;
    background-color: #D1D5DB;
    box-shadow: none !important;
    cursor: pointer;
}
.card-body{
  padding-bottom: 10px !important;
  background-color: #fff;
  border-radius: 15px !important;
}
.card-title{
  margin-bottom: 10px !important;
}
article{
      border-radius: 60px !important;
      background: transparent !important;
}
.card-img-top{
  margin-bottom: -21px !important;
}
[dir] .vue-form-wizard .wizard-tab-content {
    padding: 0px 20px 10px !important;
}
.vue-form-wizard a:hover{
  color: #E84185 !important;
}
</style>
